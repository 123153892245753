<template>
    <VuePerfectScrollbar class="d-flex flex-fill pb-0 w-100" style="padding-bottom: 0 !important; width: 10rem !important;">
        <div v-for="item in (this.$route.name === 'GPUHubMachinesFpt' ? availabilityServiceAIList : availabilityServiceList)"
            v-bind:key="item.package" class="flex-fill ml-1 mr-1 packageListDragable cursor-pointer">
            <div class="d-flex align-items-center package-report-item" 
                :style="` border: 2px solid ${getPkgStatusColor(item)};`">
                <div class="d-flex flex-row w-100 justify-content-center align-items-center">
                    <div class="mr-3 d-none d-md-block position-relative" :style="`width: calc(70px); height: 30px; `">
                        <!-- <img :src="`/img/ranks/${item.numberCard}.svg`" class="position-absolute"
                            style="height: 1.8rem; width: 2rem; left: 0rem; top: 0;" /> -->
                        <img src="/icon/gpu-vector.svg" :style="`height: 2rem; margin-left: 1.2rem`" />
                    </div>
                    <div style="color: black; font-weight: 600; font-size: 1rem" class="text-nowrap">
                        <div style="color: black; font-weight: 700; font-size: 0.8rem; line-height: 1rem;"
                            class="d-flex text-center justify-content-center align-items-center w-100 text-nowrap">
                            <div style="font-size: 0.7rem">
                                {{ (listServicePack.find((x) => x.id === item.package) && listServicePack.find((x) => x.id
                                    === item.package) !== null) ? listServicePack.find((x) => x.id === item.package).description
                                    : "" }}
                            </div>
                            <div style="font-size: 0.7rem;" class="ml-2">
                                <span class="text-muted" v-if="listServicePackDict[item.package]">
                                    {{ listServicePackDict[item.package].name }}
                                </span>
                            </div>
                        </div>
                        <div style="line-height: 1rem;">
                            <el-tooltip content="Windows Available" placement="bottom" effect="light">
                                <span>
                                    <img src="/icon/os-1.svg" style="width: 0.8rem" class="mb-1" />
                                    {{ item.available }}
                                </span>
                            </el-tooltip>
                            <span class="mr-1" style="color: #757575"></span>
                            <el-tooltip content="Ubuntu Available" placement="bottom" effect="light">
                                <span>
                                    <img src="/icon/os-2.svg" style="width: 0.8rem" class="mb-1" />
                                    {{ item.availableUbuntuSupport }}
                                </span>
                            </el-tooltip>
                            <span v-if="$fixedRentalFeature" class="mr-1" style="color: #757575"></span>
                            <el-tooltip v-if="$fixedRentalFeature" content="Fixed used" placement="bottom" effect="light">
                                <span class="cursor-pointer"
                                    @click="openPopup({ type: 'fixed-rental-details-by-package', data: item })">
                                    <img src="/icon/fixedRentalUsed.svg" style="width: 0.8rem" class="mb-1" />
                                    {{ item.fixedRentalUsed }}
                                </span>
                            </el-tooltip>
                            <span class="mr-1" style="color: #757575">/</span>
                            <el-tooltip content="Total" placement="bottom" effect="light">
                                <span style="color: #757575">{{ item.total }}</span>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--</transition-group>
                            </draggable>-->
    </VuePerfectScrollbar>
</template>

<script>
import { mapState } from "vuex";
const appSquareLogo = process.env.VUE_APP_SQUARE_LOGO;
export default {
    props :{
        openPopup: {
            type: Function,
            default: null
        }
    },
    data(){
        return {
            appSquareLogo,
        };
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            listServicePack: (state) => state.common.listServicePack,
            availabilityServiceList: (state) => state.common.availabilityServiceList,
            availabilityServiceAIList: (state) => state.common.availabilityServiceAIList,
            decentralization: (state) => state.auth.decentralization,
            extraServiceRequestCount: (state) => state.extraServiceRequestCount,
            calendarData: (state) => state.common.technicalShift,
            currentCalendarIndex: (state) => parseInt(state.common.calendarIndex),
            currentDayOfWeek: (state) => parseInt(state.common.currentDayOfWeek),
        }),
        listServicePackDict() {
            return this.listServicePack.reduce(function (acc, cur, i) {
                acc[cur.id] = cur;
                return acc;
            }, {});
        },
    },
    methods: {
        getPkgStatusColor(item){
            let colorHex = '#EF5350';
            switch(item.statusText){
                case 'low':
                    colorHex = '#66BB6A';
                    break;
                case 'medium':
                    colorHex = '#42A5F5';
                    break;
                case 'high':
                    colorHex = '#FFA726';
                    break;
                default:
                    colorHex = '#EF5350';
                    break;
            }
            return colorHex;                
        }
    }
}
</script>

<style scoped>
.package-report-item{
    background: #fffc; /* url('/static/package-info-bg.jpg'); 
    /* background-position-y: center; 
    background-size: cover; */
    border-radius: 0.5rem; 
    padding: 0.2rem 0.2rem 0.2rem;
}
</style>